var laserwave = Object.freeze({
  "colors": {
    "activityBar.activeBorder": "#EB64B9",
    "activityBar.background": "#27212e",
    "activityBar.foreground": "#ddd",
    "activityBarBadge.background": "#EB64B9",
    "button.background": "#EB64B9",
    "diffEditor.border": "#b4dce7",
    "diffEditor.insertedTextBackground": "#74dfc423",
    "diffEditor.removedTextBackground": "#eb64b940",
    "editor.background": "#27212e",
    "editor.findMatchBackground": "#40b4c48c",
    "editor.findMatchHighlightBackground": "#40b4c460",
    "editor.foreground": "#ffffff",
    "editor.selectionBackground": "#eb64b927",
    "editor.selectionHighlightBackground": "#eb64b927",
    "editor.wordHighlightBackground": "#eb64b927",
    "editorError.foreground": "#ff3e7b",
    "editorGroupHeader.tabsBackground": "#242029",
    "editorGutter.addedBackground": "#74dfc4",
    "editorGutter.deletedBackground": "#eb64B9",
    "editorGutter.modifiedBackground": "#40b4c4",
    "editorSuggestWidget.border": "#b4dce7",
    "focusBorder": "#EB64B9",
    "gitDecoration.conflictingResourceForeground": "#EB64B9",
    "gitDecoration.deletedResourceForeground": "#b381c5",
    "gitDecoration.ignoredResourceForeground": "#92889d",
    "gitDecoration.modifiedResourceForeground": "#74dfc4",
    "gitDecoration.untrackedResourceForeground": "#40b4c4",
    "input.background": "#3a3242",
    "input.border": "#964c7b",
    "inputOption.activeBorder": "#EB64B9",
    "list.activeSelectionBackground": "#eb64b98f",
    "list.activeSelectionForeground": "#eee",
    "list.dropBackground": "#74dfc466",
    "list.errorForeground": "#ff3e7b",
    "list.focusBackground": "#eb64ba60",
    "list.highlightForeground": "#eb64b9",
    "list.hoverBackground": "#91889b80",
    "list.hoverForeground": "#eee",
    "list.inactiveSelectionBackground": "#eb64b98f",
    "list.inactiveSelectionForeground": "#ddd",
    "list.invalidItemForeground": "#fff",
    "menu.background": "#27212e",
    "merge.currentContentBackground": "#74dfc433",
    "merge.currentHeaderBackground": "#74dfc4cc",
    "merge.incomingContentBackground": "#40b4c433",
    "merge.incomingHeaderBackground": "#40b4c4cc",
    "notifications.background": "#3e3549",
    "peekView.border": "#40b4c4",
    "peekViewEditor.background": "#40b5c449",
    "peekViewEditor.matchHighlightBackground": "#40b5c460",
    "peekViewResult.matchHighlightBackground": "#27212e",
    "peekViewResult.selectionBackground": "#40b4c43f",
    "progressBar.background": "#40b4c4",
    "sideBar.background": "#27212e",
    "sideBar.foreground": "#ddd",
    "sideBarSectionHeader.background": "#27212e",
    "sideBarTitle.foreground": "#EB64B9",
    "statusBar.background": "#EB64B9",
    "statusBar.debuggingBackground": "#74dfc4",
    "statusBar.foreground": "#27212e",
    "statusBar.noFolderBackground": "#EB64B9",
    "tab.activeBorder": "#EB64B9",
    "tab.inactiveBackground": "#242029",
    "terminal.ansiBlue": "#40b4c4",
    "terminal.ansiCyan": "#b4dce7",
    "terminal.ansiGreen": "#74dfc4",
    "terminal.ansiMagenta": "#b381c5",
    "terminal.ansiRed": "#EB64B9",
    "terminal.ansiYellow": "#ffe261",
    "titleBar.activeBackground": "#27212e",
    "titleBar.inactiveBackground": "#27212e",
    "tree.indentGuidesStroke": "#ffffff33"
  },
  "displayName": "LaserWave",
  "name": "laserwave",
  "tokenColors": [
    {
      "scope": [
        "keyword.other",
        "keyword.control",
        "storage.type.class.js",
        "keyword.control.module.js",
        "storage.type.extends.js",
        "variable.language.this.js",
        "keyword.control.switch.js",
        "keyword.control.loop.js",
        "keyword.control.conditional.js",
        "keyword.control.flow.js",
        "keyword.operator.accessor.js",
        "keyword.other.important.css",
        "keyword.control.at-rule.media.scss",
        "entity.name.tag.reference.scss",
        "meta.class.python",
        "storage.type.function.python",
        "keyword.control.flow.python",
        "storage.type.function.js",
        "keyword.control.export.ts",
        "keyword.control.flow.ts",
        "keyword.control.from.ts",
        "keyword.control.import.ts",
        "storage.type.class.ts",
        "keyword.control.loop.ts",
        "keyword.control.ruby",
        "keyword.control.module.ruby",
        "keyword.control.class.ruby",
        "keyword.other.special-method.ruby",
        "keyword.control.def.ruby",
        "markup.heading",
        "keyword.other.import.java",
        "keyword.other.package.java",
        "storage.modifier.java",
        "storage.modifier.extends.java",
        "storage.modifier.implements.java",
        "storage.modifier.cs",
        "storage.modifier.js",
        "storage.modifier.dart",
        "keyword.declaration.dart",
        "keyword.package.go",
        "keyword.import.go",
        "keyword.fsharp",
        "variable.parameter.function-call.python"
      ],
      "settings": {
        "foreground": "#40b4c4"
      }
    },
    {
      "scope": [
        "binding.fsharp",
        "support.function",
        "meta.function-call",
        "entity.name.function",
        "support.function.misc.scss",
        "meta.method.declaration.ts",
        "entity.name.function.method.js"
      ],
      "settings": {
        "foreground": "#EB64B9"
      }
    },
    {
      "scope": [
        "string",
        "string.quoted",
        "string.unquoted",
        "string.other.link.title.markdown"
      ],
      "settings": {
        "foreground": "#b4dce7"
      }
    },
    {
      "scope": [
        "constant.numeric"
      ],
      "settings": {
        "foreground": "#b381c5"
      }
    },
    {
      "scope": [
        "meta.brace",
        "punctuation",
        "punctuation.bracket",
        "punctuation.section",
        "punctuation.separator",
        "punctuation.comma.dart",
        "punctuation.terminator",
        "punctuation.definition",
        "punctuation.parenthesis",
        "meta.delimiter.comma.js",
        "meta.brace.curly.litobj.js",
        "punctuation.definition.tag",
        "puncatuation.other.comma.go",
        "punctuation.section.embedded",
        "punctuation.definition.string",
        "punctuation.definition.tag.jsx",
        "punctuation.definition.tag.end",
        "punctuation.definition.markdown",
        "punctuation.terminator.rule.css",
        "punctuation.definition.block.ts",
        "punctuation.definition.tag.html",
        "punctuation.section.class.end.js",
        "punctuation.definition.tag.begin",
        "punctuation.squarebracket.open.cs",
        "punctuation.separator.dict.python",
        "punctuation.section.function.scss",
        "punctuation.section.class.begin.js",
        "punctuation.section.array.end.ruby",
        "punctuation.separator.key-value.js",
        "meta.method-call.with-arguments.js",
        "punctuation.section.scope.end.ruby",
        "punctuation.squarebracket.close.cs",
        "punctuation.separator.key-value.css",
        "punctuation.definition.constant.css",
        "punctuation.section.array.begin.ruby",
        "punctuation.section.scope.begin.ruby",
        "punctuation.definition.string.end.js",
        "punctuation.definition.parameters.ruby",
        "punctuation.definition.string.begin.js",
        "punctuation.section.class.begin.python",
        "storage.modifier.array.bracket.square.c",
        "punctuation.separator.parameters.python",
        "punctuation.section.group.end.powershell",
        "punctuation.definition.parameters.end.ts",
        "punctuation.section.braces.end.powershell",
        "punctuation.section.function.begin.python",
        "punctuation.definition.parameters.begin.ts",
        "punctuation.section.bracket.end.powershell",
        "punctuation.section.group.begin.powershell",
        "punctuation.section.braces.begin.powershell",
        "punctuation.definition.parameters.end.python",
        "punctuation.definition.typeparameters.end.cs",
        "punctuation.section.bracket.begin.powershell",
        "punctuation.definition.arguments.begin.python",
        "punctuation.definition.parameters.begin.python",
        "punctuation.definition.typeparameters.begin.cs",
        "punctuation.section.block.begin.bracket.curly.c",
        "punctuation.definition.map.begin.bracket.round.scss",
        "punctuation.section.property-list.end.bracket.curly.css",
        "punctuation.definition.parameters.end.bracket.round.java",
        "punctuation.section.property-list.begin.bracket.curly.css",
        "punctuation.definition.parameters.begin.bracket.round.java"
      ],
      "settings": {
        "foreground": "#7b6995"
      }
    },
    {
      "scope": [
        "keyword.operator",
        "meta.decorator.ts",
        "entity.name.type.ts",
        "punctuation.dot.dart",
        "keyword.symbol.fsharp",
        "punctuation.accessor.ts",
        "punctuation.accessor.cs",
        "keyword.operator.logical",
        "meta.tag.inline.any.html",
        "punctuation.separator.java",
        "keyword.operator.comparison",
        "keyword.operator.arithmetic",
        "keyword.operator.assignment",
        "keyword.operator.ternary.js",
        "keyword.operator.other.ruby",
        "keyword.operator.logical.js",
        "punctuation.other.period.go",
        "keyword.operator.increment.ts",
        "keyword.operator.increment.js",
        "storage.type.function.arrow.js",
        "storage.type.function.arrow.ts",
        "keyword.operator.relational.js",
        "keyword.operator.relational.ts",
        "keyword.operator.arithmetic.js",
        "keyword.operator.assignment.js",
        "storage.type.function.arrow.tsx",
        "keyword.operator.logical.python",
        "punctuation.separator.period.java",
        "punctuation.separator.method.ruby",
        "keyword.operator.assignment.python",
        "keyword.operator.arithmetic.python",
        "keyword.operator.increment-decrement.java"
      ],
      "settings": {
        "foreground": "#74dfc4"
      }
    },
    {
      "scope": [
        "comment",
        "punctuation.definition.comment"
      ],
      "settings": {
        "foreground": "#91889b"
      }
    },
    {
      "scope": [
        "meta.tag.sgml",
        "entity.name.tag",
        "entity.name.tag.open.jsx",
        "entity.name.tag.close.jsx",
        "entity.name.tag.inline.any.html",
        "entity.name.tag.structure.any.html"
      ],
      "settings": {
        "foreground": "#74dfc4"
      }
    },
    {
      "scope": [
        "variable.other.enummember",
        "entity.other.attribute-name",
        "entity.other.attribute-name.jsx",
        "entity.other.attribute-name.html",
        "entity.other.attribute-name.id.css",
        "entity.other.attribute-name.id.html",
        "entity.other.attribute-name.class.css"
      ],
      "settings": {
        "foreground": "#EB64B9"
      }
    },
    {
      "scope": [
        "variable.other.property",
        "variable.parameter.fsharp",
        "support.variable.property.js",
        "support.type.property-name.css",
        "support.type.property-name.json",
        "support.variable.property.dom.js"
      ],
      "settings": {
        "foreground": "#40b4c4"
      }
    },
    {
      "scope": [
        "constant.language",
        "constant.other.elm",
        "constant.language.c",
        "variable.language.dart",
        "variable.language.this",
        "support.class.builtin.js",
        "support.constant.json.ts",
        "support.class.console.ts",
        "support.class.console.js",
        "variable.language.this.js",
        "variable.language.this.ts",
        "entity.name.section.fsharp",
        "support.type.object.dom.js",
        "variable.other.constant.js",
        "variable.language.self.ruby",
        "variable.other.constant.ruby",
        "support.type.object.console.js",
        "constant.language.undefined.js",
        "support.function.builtin.python",
        "constant.language.boolean.true.js",
        "constant.language.boolean.false.js",
        "variable.language.special.self.python",
        "support.constant.automatic.powershell"
      ],
      "settings": {
        "foreground": "#ffe261"
      }
    },
    {
      "scope": [
        "variable.other",
        "variable.scss",
        "meta.function-call.c",
        "variable.parameter.ts",
        "variable.parameter.dart",
        "variable.other.class.js",
        "variable.other.object.js",
        "variable.other.object.ts",
        "support.function.json.ts",
        "variable.name.source.dart",
        "variable.other.source.dart",
        "variable.other.readwrite.js",
        "variable.other.readwrite.ts",
        "support.function.console.ts",
        "entity.name.type.instance.js",
        "meta.function-call.arguments",
        "variable.other.property.dom.ts",
        "support.variable.property.dom.ts",
        "variable.other.readwrite.powershell"
      ],
      "settings": {
        "foreground": "#fff"
      }
    },
    {
      "scope": [
        "storage.type.annotation",
        "punctuation.definition.annotation",
        "support.function.attribute.fsharp"
      ],
      "settings": {
        "foreground": "#74dfc4"
      }
    },
    {
      "scope": [
        "entity.name.type",
        "storage.type",
        "keyword.var.go",
        "keyword.type.go",
        "keyword.type.js",
        "storage.type.js",
        "storage.type.ts",
        "keyword.type.cs",
        "keyword.const.go",
        "keyword.struct.go",
        "support.class.dart",
        "storage.modifier.c",
        "storage.modifier.ts",
        "keyword.function.go",
        "keyword.operator.new.ts",
        "meta.type.annotation.ts",
        "entity.name.type.fsharp",
        "meta.type.annotation.tsx",
        "storage.modifier.async.js",
        "punctuation.definition.variable.ruby",
        "punctuation.definition.constant.ruby"
      ],
      "settings": {
        "foreground": "#a96bc0"
      }
    },
    {
      "scope": [
        "markup.bold",
        "markup.italic"
      ],
      "settings": {
        "foreground": "#EB64B9"
      }
    },
    {
      "scope": [
        "meta.object-literal.key.js",
        "constant.other.object.key.js"
      ],
      "settings": {
        "foreground": "#40b4c4"
      }
    },
    {
      "scope": [],
      "settings": {
        "foreground": "#ffb85b"
      }
    },
    {
      "scope": [
        "meta.diff",
        "meta.diff.header"
      ],
      "settings": {
        "foreground": "#40b4c4"
      }
    },
    {
      "scope": [
        "meta.diff.range.unified"
      ],
      "settings": {
        "foreground": "#b381c5"
      }
    },
    {
      "scope": [
        "markup.deleted",
        "punctuation.definition.deleted.diff",
        "punctuation.definition.from-file.diff",
        "meta.diff.header.from-file"
      ],
      "settings": {
        "foreground": "#eb64b9"
      }
    },
    {
      "scope": [
        "markup.inserted",
        "punctuation.definition.inserted.diff",
        "punctuation.definition.to-file.diff",
        "meta.diff.header.to-file"
      ],
      "settings": {
        "foreground": "#74dfc4"
      }
    }
  ],
  "type": "dark"
});

export { laserwave as default };
